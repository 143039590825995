
/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

/*require('./bootstrap');*/

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */

/*
Vue.component('example', require('./components/Example.vue'));

const app = new Vue({
    el: 'body'
});
*/
$('#twitchmodal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var streamurl = button.data('streamurl') // Extract info from data-* attributes
    var streamstatus = button.data('streamtitle')
    var streamname = button.data('streamname')

    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).

    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this)
    modal.find('.modal-title').text(streamname + ' - ' + streamstatus)
    modal.find('.modal-body .stream iframe').attr('src', streamurl + "/embed")
    modal.find('.modal-body .chat iframe').attr('src', streamurl + "/chat")

})

$('#twitchmodal').on('hide.bs.modal', function (event) {
    var modal = $(this)
    modal.find('.modal-body iframe').attr('src', '')
    //Remove stream from modal.

})